import React, { useEffect, useState } from "react";
import { Card, Col, Row } from "react-bootstrap";
import { useSelector } from "react-redux";
import './style.scss'
import { Translate } from "../../Enums/Tranlate";
import AdminService from "../../../services/AdminService";
import { useNavigate } from "react-router-dom";
import administrator from '../../../images/icons/administrator1.png'
import blog from '../../../images/icons/blog.png'
import service from '../../../images/icons/services.png'
import clients from '../../../images/icons/employee.png'

const Dashboard = () => {
  const [formData ,setFormData] = useState({
    totalAdmins: '0',
    totalBlogs: '0',
    totalServices: '0',
    totalClients: '0',
  })
  const [loading, setLoading] = useState(false)
  const lang = useSelector(state=> state.auth.lang)
  const navigate = useNavigate()

  useEffect(()=>{
    setLoading(true)
    new AdminService().getDashboard().then(res=>{
      if(res?.status){
        setFormData(res.data.data)
      }
      setLoading(false)
    }).catch(()=> setLoading(false))
  },[])

  if(loading) {
    return <Row>
      {Object.entries(formData)?.map(data=>{
        return <Col className="col-md-4 col-sm-12">
          <Card style={{height: '128.75px'}}>
            <Card.Body>
              <div class="skeleton-loader">
                  <div class="loader-header"></div>
                  <div class="loader-content"></div>
              </div>
            </Card.Body>
          </Card>
        </Col>
      })}
    </Row>
  }

  return (
        <div className="row dashboard">
          <div className="col-md-4 col-sm-12">
            <div className="card"  onClick={()=> navigate('/admins')}>
            
              <div className="card-body">
                <h2>{formData.totalAdmins}</h2>
                <img src={administrator} alt='administrator' width={50} />
                {/* <i className='la la-user-shield'></i> */}
                <div>
                  <span className="fs-16 font-w600">
                     {Translate[lang]?.admins}
                  </span>
                </div>
              </div>
            </div>
          </div>
          <div className="col-md-4 col-sm-12">
            <div className="card"  onClick={()=> navigate('/blogs')}>
              <div className="card-body">
                <h2>{formData.totalBlogs}</h2>
                <img src={blog} alt='blogs' width={50} />
                <div>
                  <span className="fs-16 font-w600">
                    {Translate[lang]?.blogs}
                  </span>
                </div>
              </div>
            </div>
          </div>
          <div className="col-md-4 col-sm-12">
            <div className="card"  onClick={()=> navigate('/services')}>
              <div className="card-body">
                <h2>{formData.totalServices}</h2>
                <img src={service} alt='service' width={50} />
                <div>
                  <span className="fs-16 font-w600">
                    {Translate[lang]?.services}
                  </span>
                </div>
              </div>
            </div>
          </div>
          <div className="col-md-4 col-sm-12">
            <div className="card"  onClick={()=> navigate('/clients')}>
              <div className="card-body">
                <h2>{formData.totalClients}</h2>
                <img src={clients} alt='clients' width={50} />
                <div>
                  <span className="fs-16 font-w600">
                    {Translate[lang]?.clients}
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
  );
};

export default Dashboard;
