import { Button, Card, Col, Row } from "react-bootstrap";
import {AvField, AvForm} from "availity-reactstrap-validation";
import { EditorState, convertToRaw, ContentState } from "draft-js";
import { useEffect, useState } from "react";
import { Editor } from "react-draft-wysiwyg";
import draftToHtml from 'draftjs-to-html';
import htmlToDraft from 'html-to-draftjs';
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import 'draft-js/dist/Draft.css';
import StaticPagesServices from "../../../../services/StaticPagesService";
import { toast } from "react-toastify";
import { useSelector } from "react-redux";
import { Translate } from "../../../Enums/Tranlate";
import Loader from "../../../common/Loader";
import { useLocation } from "react-router-dom";
import '../style.scss'
import BaseService from "../../../../services/BaseService";
import uploadImg from "../../../../images/upload-img.png"

const Static = () =>{
    const [formData, setFormData] =useState([
        {
            title:'',
            // title_ar:'',
            // description_ar: EditorState.createEmpty(),
            description: EditorState.createEmpty(),
            images: [
                {src: "", loading: false}
            ],
        }
    ])
    // const Auth = useSelector(state=> state.auth?.auth)
    const lang = useSelector(state=> state.auth.lang)
    // const isExist = (data)=> Auth?.admin?.admin_roles?.includes(data)
    // const [isEdit, setIsEdit] = useState()
    const [loading, setLoading] = useState()
    const [submitLoading, setSubmitLoading] = useState(false)
    const staticPagesServices = new StaticPagesServices()
    const location = useLocation()

    const changeInput = (e,name,index) =>{
        let update = formData.map((item,ind)=>{
            if(index === ind){
                return{
                    ...item,
                    [name]: e.target.value
                }
            } else{
                return item
            }
        })
        setFormData(update)
    }

    useEffect(()=>{
        let params = {type: 'about'}
        setLoading(true)
        staticPagesServices.getList(params).then(res=>{
            if(res?.status === 200){
                if(res.data.data?.length === 0){
                    setFormData([{
                        title:'',
                        // title_ar:'',
                        // description_ar: EditorState.createEmpty(),
                        description: EditorState.createEmpty(),
                        images: [
                            {src: "", loading: false},
                            {src: "", loading: false}
                        ],
                    }])
                } else {
                    let data = res.data.data?.map(item =>{
                        return{
                            title: item?.title,
                            description: EditorState.createWithContent(ContentState.createFromBlockArray(htmlToDraft(item.description))),
                            images: item?.static_image?.map(img=> {
                                return {
                                    src: img?.url,
                                    loading: false
                                }
                            })
                        }
                    })
                    setFormData(data)
                }
            }
            setLoading(false)
        })
    },[location])

    const submit = () =>{
        let data ={
            // type: location?.pathname === '/policy'? 'faqs' :'about',
            type: 'about',
            static_page: formData.map(res=>{
                let description = draftToHtml(convertToRaw(res.description.getCurrentContent()))
                return{
                    static_image: res?.images?.filter(res1=> !!res1?.src)?.map(res1=> res1?.src),
                    title: res?.title,
                    description: description
                }
            })
        }
        setSubmitLoading(true)
        staticPagesServices.create(data).then(res=>{
            if(res?.status === 201){
                toast.success("Update Data Successfullly")
            }
            setSubmitLoading(false)
        })
    }

    const fileHandler = (e, index, ind) => {
        if(e.target.files?.length === 0){
          return
        }
        let filesAll = e.target.files;
        const filesData = Object.values(filesAll);
    
        if(!!filesData?.length){
          let update = formData?.map((response, responseInd) => {
            if(responseInd === index){
                let info = response?.images?.map((res,resInd)=>{
                    if(ind === resInd){
                      return {
                        src: "",
                        loading: true
                      }
                    } else {
                      return res
                    }
                })
                return {
                    ...response,
                    images: info
                }
            } else {
                return response
            }
          })
          setFormData(update);
      
          new BaseService().postUpload(filesData[0]).then((res) => {
            if (res?.data?.status) {
                let update = formData?.map((response, responseInd) => {
                    if(responseInd === index){
                        let info = response?.images?.map((resp,resInd)=>{
                            if(ind === resInd){
                              return {
                                src: res.data.url,
                                loading: false
                              }
                            } else {
                              return resp
                            }
                        })
                        return {
                            ...response,
                            images: info
                        }
                    } else {
                        return response
                    }
                })
                setFormData(update);
            }
          });
        }
    };

    if(loading){
        return <Card className="py-5" style={{height: '300px'}}>
            <Card.Body>
                <Loader />
            </Card.Body>
      </Card>
    }

    return<>
    <Card className="about">
        <Card.Body>
        <AvForm onValidSubmit={submit}>
            {formData?.map((item, index)=>{
                return <Row className="position-relative" key={index} style={{borderBottom: '2px solid #dedede', paddingBottom: '1rem' ,marginBottom: '3rem'}}>
                    {index > 0 && <button className="delete border-0"  type="button" onClick={()=>{
                        let update = formData.filter((_,ind) => ind !== index)
                        setFormData(update)
                    }}>
                        <i className="la la-times"></i>
                    </button>}
                <Col md={12} className="form-group mb-2">
					<AvField
						label ={Translate[lang].title}
						name ={`title${index}`}
						type="text" 
						value={item.title}
                        // disabled={isEdit}
						validate={{
							required: {
								value:true,
								errorMessage: Translate[lang].field_required
							},
						}}
						placeholder={Translate[lang].title}
						onChange={(e) => changeInput(e,'title',index)}
					/>
				</Col>
                {/* <Col md={6} className="form-group mb-2">
					<AvField
						label ={Translate[lang].arabic_title}
					    name ={`title_ar${index}`}
						type="text" 
                        // disabled={isEdit}
						value={item.title_ar}
						validate={{
							required: {
								value:true,
								errorMessage: Translate[lang].field_required
							},
						}}
						placeholder={Translate[lang].arabic_title}
						onChange={(e) => changeInput(e,'title_ar',index)}
					/>
				</Col> */}
                <Col md={12} className="form-group" style={{marginBottom: '1rem'}}>
                    <label className="d-block">{Translate[lang].description}</label>
                    <div className="editorField">
                        <Editor
                            // editorState ={editorState}
                            editorState ={item.description}
                            toolbarClassName="toolbarClassName"
                            wrapperClassName="wrapperClassName"
                            editorClassName="editorClassName"
                            onEditorStateChange={(e) => {
                                // if(isEdit){
                                //     return
                                // }
                                let update = formData.map((item,ind)=>{
                                    if(index === ind){
                                        return{
                                            ...item,
                                            description: e
                                        }
                                    } else{
                                        return item
                                    }
                                })
                                setFormData(update)
                            }}
                        />
                    </div>
				</Col>
                <Col md={12} sm={12}>
                    <label className='mb-0'>{Translate[lang].images}</label>
                </Col>
                {item?.images?.map((image, ind)=> {
                    return <Col md={8} sm={12} className="mb-3" key={index}>
                    <div className="image-placeholder position-relative">
                        <div className="avatar-edit w-100 h-100">
                            <input
                            type="file"
                            className="w-100 h-100 d-block cursor-pointer"
                            style={{opacity: '0'}}
                            onChange={(e) => fileHandler(e, index, ind)}
                            id={`imageUpload${index}`}
                            />
                            {/* <label htmlFor={`imageUpload`} name=""></label> */}
                        </div>
                        <button
                            className="delete"
                            type="button"
                            style={{
                            left: lang === 'ar' ? '36px' : "auto",
                            right: lang === 'en' ? '36px' : "auto",
                            }}
                            onClick={() => {
                                let update = formData?.map((response, responseInd) => {
                                    if(responseInd === index){
                                        let info = response?.images?.map((res,resInd)=>{
                                            if(ind === resInd){
                                              return {
                                                src: "",
                                                loading: false
                                              }
                                            } else {
                                              return res
                                            }
                                        })
                                        return {
                                            ...response,
                                            images: info
                                        }
                                    } else {
                                        return response
                                    }
                                  })
                                  setFormData(update);
                            }}
                        >
                            <i className="la la-trash"></i>
                        </button>
                        <div className="avatar-preview">
                            {!!image?.src ? (
                            <div id={`imagePreview`}>
                                <img
                                id={`saveImageFile`}
                                src={image?.src }
                                alt="icon"
                                />
                            </div>
                            ) : (
                            <div id={`imagePreview`}>
                                {(!image.src && image.loading)  && <Loader></Loader>}
                                {(!image?.src && !image?.loading) && (
                                <img
                                    id={`saveImageFile`}
                                    src={uploadImg}
                                    alt="icon"
                                    style={{
                                    width: "100px", height: "100px",
                                    maxHeight: "100px", maxWidth: "100px"
                                    }}
                                />
                                )}
                            </div>
                            )}
                        </div>
                    </div>
                    {ind === 0 && <span style={{fontSize: '14px'}}>Image: 600x400</span>}
                </Col>
                })}
                {/* <Col md={6} className="form-group"  style={{marginBottom: '4rem'}}>
                    <label className="d-block">{Translate[lang].arabic_description}</label>
                    <div className="editorField">
                        <Editor
                            // editorState ={editorState}
                            editorState ={item.description_ar}
                            toolbarClassName="toolbarClassName"
                            wrapperClassName="wrapperClassName"
                            editorClassName="editorClassName"
                            onEditorStateChange={(e) => {
                                // if(isEdit){
                                //     return
                                // }
                                let update = formData.map((item,ind)=>{
                                    if(index === ind){
                                        return{
                                            ...item,
                                            description_ar: e
                                        }
                                    } else{
                                        return item
                                    }
                                })
                                setFormData(update)
                            }}
                        />
                    </div>
				</Col> */}
            </Row>
            })}
            
            {/* {isExist('static_pages') &&  */}
            <div className="d-flex mt-5 justify-content-between">
                <Button 
                    // disabled={isEdit}
                    variant="secondary" 
                    onClick={()=>{
                    setFormData([...formData, {
                        title: '',
                        // title_ar:'',
                        // description_ar: EditorState.createEmpty(),
                        description: EditorState.createEmpty(),
                        images: [
                            {src: "", loading: false},
                            {src: "", loading: false}
                        ],
                    }])
                }}>
                    {Translate[lang].add_new_value}
                </Button>
                <Button variant="primary" disabled={submitLoading} type="submit">
                    {Translate[lang].submit}
                </Button>
            </div>
            {/* } */}
        </AvForm>
        </Card.Body>
    </Card>
    </>
}
export default Static;