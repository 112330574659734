import dashboard from '../../images/icons/dashboard1.png'
import administrator from '../../images/icons/administrator1.png'
import about from '../../images/icons/about-us.png'
import slider from '../../images/icons/slider.png'
import blog from '../../images/icons/blog.png'
import portfolio from '../../images/icons/portfolio.png'
import social from '../../images/icons/social-media.png'
import contact from '../../images/icons/chat.png'
import services from '../../images/icons/services.png'
import employee from '../../images/icons/employee.png'

export const MenuList = [
  // Statics
  {
    title: "Statics",
    classsChange: "mm-collapse",
    iconStyle: <img src={dashboard} width={22} alt='shield' />,
    // iconStyle: <i class="lab la-buffer"></i>,
    to: "",
    text: "statics",
  },
  // Admins
  {
    title: "Admins Management",
    classsChange: "mm-collapse",
    iconStyle: <img src={administrator} width={22} alt='shield' />,
    // iconStyle: <i className="la la-user-shield"></i>,
    to: "admins",
    text: "admins",
  },
  // Rules
  // {
  //   title: "Rules",
  //   classsChange: "mm-collapse",
  //   iconStyle: <img src={shield} width={22} alt='shield' />,
  //   // iconStyle: <i className="la la-shield"></i>,
  //   to: "rules",
  //   text: "rules",
  // },
  // Banners
  {
    title: "Banners",
    classsChange: "mm-collapse",
    iconStyle: <img src={slider} width={22} alt='shield' />,
    // iconStyle: <i className="la la-codepen"></i>,
    to: "banners",
    text: "banners",
  },
  // Services
  {
    title: "Services",
    classsChange: "mm-collapse",
    iconStyle: <img src={services} width={22} alt='shield' />,
    to: "services",
    text: "services",
  },
  // Blogs
  {
    title: "Blogs",
    classsChange: "mm-collapse",
    iconStyle: <img src={blog} width={22} alt='shield' />,
    // iconStyle: <i className="la la-codepen"></i>,
    to: "blogs",
    text: "blogs",
  },
  // Portfolio
  {
    title: "Portfolio",
    classsChange: "mm-collapse",
    iconStyle: <img src={portfolio} width={22} alt='shield' />,
    // iconStyle: <i className="la la-codepen"></i>,
    to: "portfolio",
    text: "portfolio",
  },
  // Clients
  {
    title: "Clients",
    classsChange: "mm-collapse",
    iconStyle: <img src={employee} width={22} alt='shield' />,
    to: "clients",
    text: "clients",
  },
  // Contact Us
  {
    title: "Contact Us",
    classsChange: "mm-collapse",
    iconStyle: <img src={contact} width={22} alt='shield' />,
    to: "contact-us",
    text: "contact_us",
  },
  // About Us
  {
    title: "About Us",
    classsChange: "mm-collapse",
    iconStyle: <img src={about} width={22} alt='shield' />,
    to: "about-us",
    text: "about",
  },
  // Socail
  {
    title: "Social",
    classsChange: "mm-collapse",
    iconStyle: <img src={social} width={22} alt='shield' />,
    to: "social",
    text: "social",
  },
]