export const SocialMediaLinks =[
    {label: 'Gmail', value: 'gmail'},
    {label: 'Call Us', value: 'call_us'},
    // {label: 'Fax', value: 'fax'},
    {label: 'Address', value: 'address'},
    {label: 'Address Link', value: 'address_link'},
    {label: 'Snapchat', value: 'snapchat'},
    {label: 'Facebook', value: 'facebook'},
    {label: 'Instagram', value: 'instagram'},
    {label: 'Whatsapp', value: 'whatsapp'},
    {label: 'Twitter', value: 'twitter'},
    // {label: 'Linked In', value: 'linked_in'},
    {label: 'Telegram', value: 'telegram'},
    {label: 'Tiktok', value: 'tiktok'},
    // {label: 'App Store', value: 'app_store'},
    // {label: 'Play Store', value: 'play_store'},
    // {label: 'Talabat', value: 'talabat'},
    {label: 'Youtube', value: 'you_tube'},
] 