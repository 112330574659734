import React from "react";
import { Routes, Route, Outlet } from "react-router-dom";
/// Css
import "./index.css";
import "./chart.css";
import "./step.css";

/// Layout
import Nav from "./layouts/nav";

/// Pages
import Error404 from "./common/Error404";

//Scroll To Top
import ScrollToTop from "./layouts/ScrollToTop";

import Admins from "./pages/Admin";
import AddAdmin from "./pages/Admin/AddAdmin";
import Permission from "./pages/CustomRules";
import Profile from "./pages/Profile";
import Dashboard from "./pages/Dashboard";
import Banners from "./pages/Banners";
import Blogs from "./pages/Blogs";
import AddBlog from "./pages/Blogs/AddBlog";
import Static from "./pages/StaticPages/Static";
import SocialMedia from "./pages/SocialMedia";
import Clients from "./pages/Clients";
import ContactUs from "./pages/ContactUs";
import Services from "./pages/Services";
import AddService from "./pages/Services/AddService";
import Portfolio from "./pages/Portfolio";
import AddPortfolio from "./pages/Portfolio/AddPortfolio";

const Markup = () => {
  const allroutes = [
    // Dashboard
    { url: "/", component: <Dashboard /> },

    // Admins
    { url: "/admins", component: <Admins /> },
    { url: "add-admins", component: <AddAdmin /> },
    { url: "edit-admin/:id/:name", component: <AddAdmin /> },

    // Rules
    // { url: "rules", component: <Permission /> },
    // { url: "rules/:id", component: <Permission /> },

    // Banners
    { url: "banners", component: <Banners /> },

    // Services
    { url: "services", component: <Services /> },
    { url: "add-service", component: <AddService /> },
    { url: "edit-service/:id", component: <AddService /> },

    // Blogs
    { url: "blogs", component: <Blogs /> },
    { url: "add-blog", component: <AddBlog /> },
    { url: "edit-blog/:id", component: <AddBlog /> },

    // [ortfolio
    { url: "portfolio", component: <Portfolio /> },
    { url: "add-portfolio", component: <AddPortfolio /> },
    { url: "edit-portfolio/:id", component: <AddPortfolio /> },

    // Profile
    { url: "profile", component: <Profile /> },

    // Clients
    { url: "clients", component: <Clients /> },

    // Contact Us
    { url: "contact-us", component: <ContactUs /> },

    // About Us
    { url: "about-us", component: <Static /> },

    // Social Media
    { url: "social", component: <SocialMedia /> },

    // Error
    { url: "*", component: <Error404 /> },
  ];

  return (
    <>
      <Routes>
        <Route path="page-error-404" element={<Error404 />} />
        <Route element={<MainLayout />}>
          {allroutes.map((data, i) => (
            <Route
              key={i}
              exact
              path={`${data.url}`}
              element={data.component}
            />
          ))}
        </Route>
      </Routes>
      <ScrollToTop />
    </>
  );
};

function MainLayout() {
  return (
    <div id="main-wrapper" className={`show `}>
      <Nav />
      <div
        className="content-body"
        style={{ minHeight: window.screen.height - 45 }}
      >
        <div className="container-fluid">
          <Outlet />
        </div>
      </div>
    </div>
  );
}

export default Markup;
