import { useEffect, useState } from "react";
import { Button, Card, Col, Form, Row } from "react-bootstrap";
import { AvField, AvForm } from "availity-reactstrap-validation";
import "../style.scss";
import { toast } from "react-toastify";
import { useLocation, useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { Translate } from "../../../Enums/Tranlate";
import BaseService from "../../../../services/BaseService";
import Loader from "../../../common/Loader";
import uploadImg from "../../../../images/upload-img.png"
import ServiceService from "../../../../services/ServiceService";
import { Editor } from "react-draft-wysiwyg";
import { EditorState, convertToRaw, ContentState } from "draft-js";
import draftToHtml from 'draftjs-to-html';
import htmlToDraft from 'html-to-draftjs';
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import 'draft-js/dist/Draft.css';

const AddService= () => {
  const [service, setService] = useState({
    title: "",
    description: EditorState.createEmpty(),
    icon: {src: "", loading: ""},
    service_image: [{src: "", loading: ""}, {src: "", loading: ""}]
  });
  const [errors, setErrors] = useState({
    description: false,
    image: false,
    icon: false,
  })
  const [confirm, setConfirm] = useState(false);
  const [id, setId] = useState(null);
  const [loading, setLoadning] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();
  const Auth = useSelector((state) => state.auth);
  const lang = useSelector((state) => state.auth.lang);
  const serviceService = new ServiceService()

  useEffect(()=>{
    if(!!location.state){
      setId(location.state?.id)
      setService({
        ...location.state,
        icon: {src: location.state?.icon, loading: false},
        service_image: location?.state?.service_image?.map(res=> {
          return {
            src: res?.url,
            loading: false
          }
        }),
        description: EditorState.createWithContent(ContentState.createFromBlockArray(htmlToDraft(location.state?.description))),
      })
    } else {
      let des = localStorage.getItem("LeapServiceDescription") ? EditorState.createWithContent(ContentState.createFromBlockArray(htmlToDraft(JSON.parse(localStorage.getItem("LeapServiceDescription"))))) : ''
      setService({
        ...service,
        description: des
      })
    }
  },[])

  const fileIconHandler = (e) => {
    if(e.target.files?.length === 0){
      return
    }
    let filesAll = e.target.files;
    const filesData = Object.values(filesAll);

    if(!!filesData?.length){
      setService({ ...service, icon: {src: "", loading: false} });
      new BaseService().postUpload(filesData[0]).then((res) => {
        if (res?.data?.status) {
          setService({ ...service, icon: {src: res.data.url, loading: false} });
        }
      });
    }
  };
  const fileHandler = (e, index) => {
    if(e.target.files?.length === 0){
      return
    }
    let filesAll = e.target.files;
    const filesData = Object.values(filesAll);

    if(!!filesData?.length){
      let update = service?.service_image?.map((res,ind)=>{
        if(ind === index){
          return {
            src: "",
            loading: true
          }
        } else {
          return res
        }
      })
      setService({ ...service, service_image: update });
  
      new BaseService().postUpload(filesData[0]).then((res) => {
        if (res?.data?.status) {
          let update = service?.service_image?.map((res1,ind)=>{
            if(ind === index){
              return {
                src: res.data.url,
                loading: false
              }
            } else {
              return res1
            }
          })
          setService({ ...service, service_image: update });
        }
      });
    }
  };

  const handlerText = (e) => {
    setService({ ...service, [e.target.name]: e.target.value });
  };

  const submit = () => {
    if (!service.description || !service.icon || !service?.service_image?.length) {
      setErrors({
        description: !service.description,
        icon: !service.icon,
        image: !service.service_image?.length,
      });
      return;
    }

    setLoadning(true);
    let data = {
      title: service.title,
      description: draftToHtml(convertToRaw(service?.description.getCurrentContent())),
      icon: service?.icon?.src,
      service_image: service?.service_image?.filter(res=> !!res?.src)?.map(res=> res?.src)
    };

    if (!!id) {
      serviceService.update(id, data)?.then((res) => {
        if (res.data?.status === 200) {
          toast.success("Service Updated Successfully");
          localStorage.removeItem("LeapServiceDescription")
          navigate(`/services`)
        }
        setLoadning(false);
      });
    } else {
      serviceService.create(data)?.then((res) => {
        if (res.data?.status === 201) {
          setConfirm(true);
          toast.success("Service Added Successfully");
          localStorage.removeItem("LeapServiceDescription")
          navigate(`/services`)
        }
        setLoadning(false);
      });
    }
  };

  const deleteIconImg = () => {
    setService({ ...service, icon: {src: "", loading: false} });
  };

  if (Auth.showLoading) {
    return (
      <Card className="p-4" style={{ minHeight: "30rem" }}>
        <Loader />
      </Card>
    );
  }
  return (
    <Card className="p-4">
      <AvForm onValidSubmit={submit} className="add-service">
        <Row>
        <Col md={12} sm={12} className="mb-3 icon">
            <label className="text-label">
              {Translate[lang]?.icon}
            </label>
            <div className="image-placeholder">
                  <div className="avatar-edit w-100 h-100">
                    <input
                      type="file"
                      className="w-100 h-100 d-block cursor-pointer"
                      style={{opacity: '0'}}
                      onChange={(e) => fileIconHandler(e)}
                      id={`imageUpload`}
                    />
                    {/* <label htmlFor={`imageUpload`} name=""></label> */}
                  </div>
                  <button
                    className="delete-img"
                    type="button"
                    style={{
                      left: lang === 'ar' ? '16px' : "auto",
                      right: lang === 'en' ? '16px' : "auto",
                    }}
                    onClick={() => deleteIconImg()}
                  >
                    <i className="la la-trash"></i>
                  </button>
                  <div className="avatar-preview">
                    {!!service?.icon?.src ? (
                      <div id={`imagePreview`}>
                        <img
                          id={`saveImageFile`}
                          src={service?.icon?.src }
                          alt="icon"
                        />
                      </div>
                    ) : (
                      <div id={`imagePreview`}>
                        {(!service?.icon.src && service?.icon.loading)  && <Loader></Loader>}
                        {(!service?.icon?.src && !service?.icon?.loading) && (
                          <img
                            id={`saveImageFile`}
                            src={uploadImg}
                            alt="icon"
                            style={{
                              width: "75px", height: "75px",
                              maxHeight: "75px", maxWidth: "75px"
                            }}
                          />
                        )}
                      </div>
                    )}
                  </div>
            </div>
            <span style={{fontSize: '14px'}}>Image: 60X60</span>
          </Col>
          <Col md={12} className="mb-3">
            <AvField
              label={`${Translate[lang]?.title}`}
              type="text"
              placeholder={Translate[lang]?.title}
              bsSize="lg"
              name="title"
              validate={{
                required: {
                  value: true,
                  errorMessage: Translate[lang].field_required,
                },
              }}
              value={service.title}
              onChange={(e) => handlerText(e)}
            />
          </Col>
          <Col md={12} className="mb-3">
            <label className="text-label">
              {Translate[lang]?.description}
            </label>
            <Editor
                editorState ={service?.description}
                toolbarClassName="toolbarClassName"
                wrapperClassName="wrapperClassName"
                editorClassName="editorClassName"
                onEditorStateChange={(e) => {
                  setService({...service, description: e})
                  let des = draftToHtml(convertToRaw(e.getCurrentContent()))
                  localStorage.setItem('LeapServiceDescription', JSON.stringify(des))
                }}
            />
            {errors.description && (
              <p className="text-danger m-0" style={{ fontSize: "12.8px" }}>
                {Translate[lang].field_required}
              </p>
            )}
          </Col>
        </Row>

        <Row>
          <Col md={12} sm={12} className="mb-0">
            <label className="text-label m-0">
              {Translate[lang]?.images}
            </label>
          </Col>
          {service?.service_image?.map((image, index)=> {
            return <Col md={6} sm={12} className="mb-3" key={index}>
            <div className="image-placeholder">
                  <div className="avatar-edit w-100 h-100">
                    <input
                      type="file"
                      className="w-100 h-100 d-block cursor-pointer"
                      style={{opacity: '0'}}
                      onChange={(e) => fileHandler(e, index)}
                      id={`imageUpload${index}`}
                    />
                    {/* <label htmlFor={`imageUpload`} name=""></label> */}
                  </div>
                  <button
                    className="delete-img"
                    type="button"
                    style={{
                      left: lang === 'ar' ? '16px' : "auto",
                      right: lang === 'en' ? '16px' : "auto",
                    }}
                    onClick={() => {
                      let update = service?.service_image?.map((res,ind)=>{
                        if(ind === index){
                          return {
                            src: "",
                            loading: false
                          }
                        } else {
                          return res
                        }
                      })
                      setService({ ...service, service_image: update });
                    }}
                  >
                    <i className="la la-trash"></i>
                  </button>
                  <div className="avatar-preview">
                    {!!image?.src ? (
                      <div id={`imagePreview`}>
                        <img
                          id={`saveImageFile`}
                          src={image?.src }
                          alt="icon"
                        />
                      </div>
                    ) : (
                      <div id={`imagePreview`}>
                        {(!image.src && image.loading)  && <Loader></Loader>}
                        {(!image?.src && !image?.loading) && (
                          <img
                            id={`saveImageFile`}
                            src={uploadImg}
                            alt="icon"
                            style={{
                              width: "100px", height: "100px",
                              maxHeight: "100px", maxWidth: "100px"
                            }}
                          />
                        )}
                      </div>
                    )}
                  </div>
            </div>
            {index === 0 && <span style={{fontSize: '14px'}}>Image: 690X330</span>}
          </Col>
          })}
        </Row>

        <div className="d-flex justify-content-between mt-4">
          <Button
            variant="secondary"
            type="button"
            onClick={() => navigate("/blogs")}
          >
            {Translate[lang]?.cancel}
          </Button>
          <Button variant="primary" loading={loading} type="submit">
            {!!id ? Translate[lang]?.edit : Translate[lang]?.submit}
          </Button>
        </div>
      </AvForm>
      {confirm}
    </Card>
  );
};

export default AddService;
