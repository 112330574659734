import { useState } from "react";
import { Badge, Dropdown } from "react-bootstrap";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import BlogService from "../../../../services/BlogsService";
import DeleteModal from "../../../common/DeleteModal";
import { Translate } from "../../../Enums/Tranlate";

const CardItem = ({ item, index, setShouldUpdate }) =>{
    const [deleteModal, setDeleteModal] = useState(false)
    const lang = useSelector(state=> state.auth?.lang)
    const navigate = useNavigate()
    const blogService = new BlogService()

    return(
        <tr key={index} className='text-center'>
                    <td>
                      <strong>{item.id}</strong>
                    </td>
                    <td>
                        <img
                          src={item?.image}
                          className="rounded-lg"
                          width="50"
                          height="50"
                          alt={item.id}
                        />
                    </td>
                    <td>{item.title?.slice(0, 35)} {item?.title?.length > 35 && '...'}</td>
                    <td>
                      <Badge variant="success light">{item.category}</Badge>
                    </td>
                    <td>
                      <Dropdown>
                        <Dropdown.Toggle
                          className="light sharp i-false"
                        >
                          <i className="la la-ellipsis-h" style={{fontSize: '27px'}}></i>
                        </Dropdown.Toggle>
                        <Dropdown.Menu>
                          <Dropdown.Item onClick={()=>{
                            navigate(`/edit-blog/${item.id}`, {state: item})
                          }}>{Translate[lang]?.edit}</Dropdown.Item>
                          <Dropdown.Item onClick={()=> setDeleteModal(true)}>{Translate[lang]?.delete}</Dropdown.Item>
                        </Dropdown.Menu>
                      </Dropdown>
                    </td>
                    {deleteModal && <DeleteModal
                      open={deleteModal}
                      titleMsg={item.title}
                      deletedItem={item}
                      modelService={blogService}
                      onCloseModal={setDeleteModal}
                      setShouldUpdate={setShouldUpdate}
                    />}
                  </tr>
    )
}
export default CardItem;