import { useEffect, useState } from "react";
import { Button, Card, Col, Form, Row } from "react-bootstrap";
import { AvField, AvForm } from "availity-reactstrap-validation";
import { toast } from "react-toastify";
import { useLocation, useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { Translate } from "../../../Enums/Tranlate";
import BaseService from "../../../../services/BaseService";
import Loader from "../../../common/Loader";
import uploadImg from "../../../../images/upload-img.png"
import PortfolioService from "../../../../services/PortfolioService";
import { Editor } from "react-draft-wysiwyg";
import { EditorState, convertToRaw, ContentState } from "draft-js";
import draftToHtml from 'draftjs-to-html';
import htmlToDraft from 'html-to-draftjs';
import Select from 'react-select';
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import 'draft-js/dist/Draft.css';
import "../style.scss";

const AddPortfolio = () => {
  const categories = [
    {label: "Website", value: "Website"},
    {label: "Application", value: "Application"},
    {label: "Logo", value: "Logo"},
    {label: "Branding", value: "Branding"},
  ]
  const [portfolio, setPortfolio] = useState({
    title: "",
    description: EditorState.createEmpty(),
    image: "",
    date: "",
    category: "",
    software: "",
  });
  const [errors, setErrors] = useState({
    description: false,
    image: false,
  })
  const [id, setId] = useState(null);
  const [loading, setLoadning] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();
  const Auth = useSelector((state) => state.auth);
  const lang = useSelector((state) => state.auth.lang);
  const portfolioService = new PortfolioService()

  useEffect(()=>{
    if(!!location.state){
      setId(location.state?.id)
      setPortfolio({
        ...location.state,
        image: location.state?.image,
        description: EditorState.createWithContent(ContentState.createFromBlockArray(htmlToDraft(location.state?.description))),
        category: categories?.find(res=> location?.state?.category === res?.label),
        date:location.state?.date?.split("T")[0],
        // slash: location?.state?.hash,
      })
    } else {
      let des = localStorage.getItem("LeapPortfolioDescription") ? EditorState.createWithContent(ContentState.createFromBlockArray(htmlToDraft(JSON.parse(localStorage.getItem("LeapPortfolioDescription"))))) : ''
      setPortfolio({
        ...portfolio,
        description: des
      })
    }
  },[])

  const fileHandler = (e) => {
    if(e.target.files?.length === 0){
      return
    }
    let filesAll = e.target.files;
    const filesData = Object.values(filesAll);

    new BaseService().postUpload(filesData[0]).then((res) => {
      if (res?.data?.status) {
        setPortfolio({ ...portfolio, image: res.data.url });
      }
    });
  };

  const handlerText = (e) => {
    setPortfolio({ ...portfolio, [e.target.name]: e.target.value });
  };

  const submit = () => {
    if (!portfolio?.description || !portfolio?.image || !portfolio?.category) {
      setErrors({
        description: !portfolio?.description,
        image: !portfolio?.image,
        category: !portfolio?.category,
      });
      return;
    }

    setLoadning(true);
    let data = {
      title: portfolio?.title,
      description: draftToHtml(convertToRaw(portfolio?.description.getCurrentContent())),
      image: portfolio?.image,
      category: portfolio?.category?.value,
      software: portfolio?.software,
      date: portfolio?.date,
    };

    if (!!id) {
      portfolioService.update(id, data)?.then((res) => {
        if (res?.data?.status === 200) {
          toast.success("Portfolio Updated Successfully");
          navigate(`/portfolio`)
        }
        setLoadning(false);
        localStorage.removeItem("LeapPortfolioDescription")
      }).catch(e=> console.log(e))
    } else {
      portfolioService?.create(data)?.then((res) => {
        if (res?.data?.status === 201) {
          toast.success("Portfolio Added Successfully");
          navigate(`/portfolio`)
        }
        setLoadning(false);
        localStorage.removeItem("LeapPortfolioDescription")
      }).catch(e=> console.log(e));
    }
  };

  const deleteImg = () => {
    setPortfolio({ ...portfolio, image: "" });
  };

  if (Auth.showLoading) {
    return (
      <Card className="p-4" style={{ minHeight: "30rem" }}>
        <Loader />
      </Card>
    );
  }
  return (
    <Card className="p-4">
      <AvForm onValidSubmit={submit} className="add-blog">
        <Row>
          <Col md={12} className="mb-3">
            <AvField
              label={`${Translate[lang]?.title}`}
              type="text"
              placeholder={Translate[lang]?.title}
              bsSize="lg"
              name="title"
              validate={{
                required: {
                  value: true,
                  errorMessage: Translate[lang].field_required,
                },
              }}
              value={portfolio.title}
              onChange={(e) => handlerText(e)}
            />
          </Col>
          <Col md={6} className="mb-3">
            <AvField
              label={`${Translate[lang]?.software}`}
              type="text"
              placeholder={Translate[lang]?.software}
              bsSize="lg"
              name="software"
              validate={{
                required: {
                  value: true,
                  errorMessage: Translate[lang].field_required,
                },
              }}
              value={portfolio.software}
              onChange={(e) => handlerText(e)}
            />
          </Col>
          <Col md={3} sm={6} className="mb-3">
            {/* <AvField
              label={Translate[lang]?.category}
              type="text"
              placeholder={Translate[lang]?.category}
              bsSize="lg"
              name="category"
              validate={{
                required: {
                  value: true,
                  errorMessage: Translate[lang].field_required,
                },
              }}
              value={portfolio.category}
              onChange={(e) => handlerText(e)}
            /> */}
            <label>{Translate[lang]?.category}</label>
            <Select
                value={portfolio.category}
                name="category"
                placeholder={Translate[lang]?.select}
                options={categories}
                onChange={(e)=> {
                  setErrors({...errors, category: false})
                  setPortfolio({ ...portfolio, category: e });
                }}
            />
            {errors.category && (
              <p className="text-danger m-0" style={{ fontSize: "12.8px" }}>
                {Translate[lang].field_required}
              </p>
            )}
          </Col>
          <Col md={3} sm={6} className="mb-3">
            <AvField
              label={Translate[lang]?.date}
              name="date"
              className="form-control"
              type="date"
              validate={{
                required: {
                  value: true,
                  errorMessage: Translate[lang].field_required,
                },
              }}
              value={portfolio.date}
              onChange={(e) => {
                handlerText(e);
              }}
            ></AvField>
          </Col>
          
          <Col md={12} className="mb-3">
            <label className="text-label">
              {Translate[lang]?.description}
            </label>
            <Editor
                editorState ={portfolio?.description}
                toolbarClassName="toolbarClassName"
                wrapperClassName="wrapperClassName"
                editorClassName="editorClassName"
                onEditorStateChange={(e) => {
                  setPortfolio({...portfolio, description: e})
                  let des = draftToHtml(convertToRaw(e.getCurrentContent()))
                  localStorage.setItem('LeapPortfolioDescription', JSON.stringify(des))
                }}
            />
            {errors.description && (
              <p className="text-danger m-0" style={{ fontSize: "12.8px" }}>
                {Translate[lang].field_required}
              </p>
            )}
          </Col>
        </Row>

        <Row>
          <Col md={12} sm={12} className="mb-3">
            <label className="text-label">
              {Translate[lang]?.image}
            </label>
            <div className="image-placeholder">
                  <div className="avatar-edit w-100 h-100">
                    <input
                      type="file"
                      className="w-100 h-100 d-block cursor-pointer"
                      style={{opacity: '0'}}
                      onChange={(e) => fileHandler(e)}
                      id={`imageUpload`}
                    />
                    {/* <label htmlFor={`imageUpload`} name=""></label> */}
                  </div>
                  <button
                    className="delete-img"
                    type="button"
                    style={{
                      left: lang === 'ar' ? '16px' : "auto",
                      right: lang === 'en' ? '16px' : "auto",
                    }}
                    onClick={() => deleteImg()}
                  >
                    <i className="la la-trash"></i>
                  </button>
                  <div className="avatar-preview">
                    {!!portfolio?.image ? (
                      <div id={`imagePreview`}>
                        <img
                          id={`saveImageFile`}
                          src={portfolio?.image}
                          alt="icon"
                        />
                      </div>
                    ) : (
                      <div id={`imagePreview`}>
                        {/* {files[0]?.name && (
                          <img
                            id={`saveImageFile$`}
                            src={URL.createObjectURL(files[0])}
                            alt="icon"
                          />
                        )} */}
                        {!portfolio?.image && (
                          <img
                            id={`saveImageFile`}
                            src={uploadImg}
                            alt="icon"
                            style={{
                              width: "100px", height: "100px",
                              maxHeight: "100px", maxWidth: "100px"
                            }}
                          />
                        )}
                      </div>
                    )}
                  </div>
            </div>
            <span>Image: 1410X600</span>
          </Col>
        </Row>

        <div className="d-flex justify-content-between mt-4">
          <Button
            variant="secondary"
            type="button"
            onClick={() => navigate("/portfolio")}
          >
            {Translate[lang]?.cancel}
          </Button>
          <Button variant="primary" loading={loading} type="submit">
            {!!id ? Translate[lang]?.edit : Translate[lang]?.submit}
          </Button>
        </div>
      </AvForm>
    </Card>
  );
};

export default AddPortfolio;
