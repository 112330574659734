import { Button, Card } from "react-bootstrap";
import { AvForm} from "availity-reactstrap-validation";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Translate } from "../../Enums/Tranlate";
import Loader from "../../common/Loader";
import uploadImg from "../../../images/upload-img.png";
import BaseService from "../../../services/BaseService";
import { toast } from "react-toastify";
import ClientsService from "../../../services/ClientsService";
import './style.scss'

const Clients = () => {
  const [formData, setFormData] = useState({
        our_clients: [
          { src: "", loading: false },
          { src: "", loading: false },
          { src: "", loading: false },
          { src: "", loading: false },
          { src: "", loading: false },
      ]
  })
  const [clientsLoading, setClientsLoading] = useState(false)
  const [shouldUpdate, setShouldUpdate] = useState(false)
  const lang = useSelector((state) => state.auth?.lang);
  const clientsService = new ClientsService()

  useEffect(()=>{
    let clients = clientsService?.getList()

    setClientsLoading(true)
    Promise.all([clients]).then(res=>{
      let data = {}
      if(res[0]?.status === 200){
        const clients = res[0]?.data?.data?.map(info=>{
          return {
            ...info,
            loading: false,
            src: info?.image
          }
        });
        if(clients?.length < 5){
          let complete =[]
          for(let i= clients?.length; i<5; i++){
              complete.push({src: "", loading: false})
          }
          data['our_clients']= [...clients, ...complete]
      } else {
        data['our_clients']= [...clients]
      }
      }
      setClientsLoading(false)
      setFormData({...formData, ...data})
    }).catch(() => {});
  }, [shouldUpdate])

  const fileHandler = (e, index) => {
    if(e.target.files?.length === 0){
      return
    }
    let filesAll = e.target.files;
    const filesData = Object.values(filesAll);
    let update = formData?.our_clients?.map((data, ind)=> {
      if(ind === index){
        return { src: "", loading: true }
      } else{
         return data
      }
    })
    setFormData({  ...formData, our_clients: update });
    setClientsLoading(true)
    new BaseService().postUpload(filesData[0]).then((res) => {
      if (res?.data?.status) {
        let update = formData?.our_clients?.map((data, ind)=> {
          if(ind === index){
            return { src: res.data.url, loading: false }
          } else{
             return data
          }
        })
        setFormData({ 
          ...formData, 
          our_clients: update 
        });
        setClientsLoading(false)
      }
    });
  };

  const handleImages = (e) =>{
    e.preventDefault();

    setClientsLoading(true);
    let data = {
      images: formData?.our_clients?.filter(res=> !!res?.src)?.map(res=> res?.src)
    };

    clientsService.create(data)?.then((res) => {
      if (res?.status === 201) {
        toast.success("Images Added Successfully");
        setShouldUpdate(prev=> !prev)
      }
      setClientsLoading(false);
    }).catch(()=> setClientsLoading(false));
  }

  const deleteImg = (id) => {
    clientsService?.remove(id).then(res=>{
      if(res?.status === 200){
        toast.success("Image Removed Successfully")
        let update = formData?.our_clients?.map((info)=>{
          if(info?.id === id){
            return {loading: false, src: "" }
          } else{
            return { ...info }
          }
        })
        setFormData({...formData, our_clients: update})
      }
    })
  }

  return <>
    <Card>
      <Card.Body>
        <AvForm onValidSubmit={handleImages}>
         <div className="our-clients mt-3">
            <h3>{Translate[lang]?.clients} <span style={{fontSize: '15px', color: '#666'}}>(Image 120x120)</span></h3>
            <div className="row mt-4">
                {formData?.our_clients?.map((client, index)=> {
                    return <div className="col-lg-2 col-sm-4 mb-3" key={index}>
                    <div className="image-placeholder">
                      <div className="avatar-edit w-100 h-100">
                        <input
                          type="file"
                          className="w-100 h-100 d-block cursor-pointer"
                          style={{opacity: '0'}}
                          onChange={(e) => fileHandler(e, index)}
                          id={`imageUpload-${index}`}
                        />
                      </div>
                      {!!client?.src && <button
                        className="delete-img"
                        type="button"
                        style={{
                          left: lang === 'ar' ? '-13px' : "auto",
                          right: lang === 'en' ? '-13px' : "auto",
                        }}
                        onClick={() => {
                          if(!client?.id){
                            let update = formData?.our_clients?.map((info,ind)=>{
                              if(ind === index){
                                return {loading: false, src: "" }
                              } else{
                                return { ...info }
                              }
                            })
                            setFormData({...formData, our_clients: update})
                            return
                          }
                          deleteImg(client.id)
                        }}
                      >
                        <i className="la la-trash"></i>
                      </button>}
                      <div className="avatar-preview">
                        {!!client.src ? (
                          <div id={`imagePreview-${index}`}>
                            <img
                              id={`saveImageFile-${index}`}
                              src={client?.src}
                              alt="icon"
                            />
                          </div>
                        ) : (
                          <div id={`imagePreview-${index}`}>
                            {(!client?.src && client?.loading)  && <Loader></Loader>}
                            {(!client?.src && !client?.loading) && (
                              <img
                                id={`saveImageFile-${index}`}
                                src={uploadImg}
                                alt="icon"
                                style={{
                                  width: "60px",
                                  height: "60px",
                                }}
                              />
                            )}
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                })}
                <div className="col-lg-2 col-sm-4 d-flex" style={{
                    alignItems: "center", justifyContent: "center"
                }}>
                    <div className="add-client">
                        <i className="la la-plus cursor-pointer" onClick={()=>{
                            setFormData({...formData, our_clients: [...formData.our_clients, { src: "", loading: false }]})
                        }}></i>
                    </div>
                </div>
            </div>
            <p></p>
         </div>
    
         <div className="d-flex justify-content-between mt-4">
            <Button variant="primary" type="submit" disabled={clientsLoading}>{Translate[lang]?.submit}</Button>
         </div>
        </AvForm>
      </Card.Body>
    </Card>
    </>
}
export default Clients;